<template>
    <div :class="{ active: active }" @click="onClick">{{ title }} Min</div>
</template>

<script>
export default {
    props: {
        contour: {
            type: Object,
            required: true,
        },
        layer: {
            type: Object,
            required: true,
        },
    },
    computed: {
        title() {
            return this.contour.value;
        },
        active() {
            return this.contour.visible;
        },
    },
    methods: {
        onClick() {
            this.$emit('change', this.contour);
        },
    },
};
</script>
