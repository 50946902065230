<template>
    <div class="contours-toggles">
        <p>{{ name | title }}</p>
        <div class="button-group">
            <commute-contour
                v-for="contour in contours"
                :key="contour.id"
                :contour="contour"
                :layer="layer"
                @change="onContourSelected"
            />
        </div>
    </div>
</template>

<script>
import CommuteContour from './components/contour';
import { replace } from 'utils/helpers';
import { cloneDeep } from 'utils/lodash';
import { mapActions } from 'vuex';
export default {
    components: {
        CommuteContour,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    computed: {
        name() {
            return this.layer.filters.profile;
        },
        contours() {
            return this.layer.filters.contours;
        },
    },
    methods: {
        ...mapActions('map', ['updateLayer']),
        onContourSelected(contour) {
            let contours = cloneDeep(this.contours);
            replace(contours, { value: contour.value }, { ...contour, visible: !contour.visible });

            this.updateLayer({
                ...this.layer,
                filters: { ...this.layer.filters, contours: contours },
            });
        },
    },
};
</script>
